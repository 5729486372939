import React, { useState, useEffect, useRef } from "react";
import FaqBanner from "../assets/images/faq-hero.png";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Tabs, Accordion } from "flowbite-react";
import HeroSection from "../components/HeroSection";
import faqBreadcrumb from "../assets/images/faq-main-img.png";
const Faq = () => {
  const [sections, setSections] = useState([]);
  const [articles, setArticles] = useState([]);
  const [activeSectionId, setActiveSectionId] = useState(null);
  const tabsRef = useRef(null);

  // Fetch sections
  useEffect(() => {
    fetch(
      "https://thereallifestyle.zendesk.com/api/v2/help_center/categories/36562854739481/sections.json",
      {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              "yenray.ng@trlco.world/token:O4N6Fysz582712RvT2Aux2gvbdabCExJOoh966nK"
            ),
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Sections:", data.sections);
        setSections(data.sections);
        // Set the first section as active by default
        if (data.sections.length > 0) {
          setActiveSectionId(data.sections[0].id);
        }
      })
      .catch((error) => console.error("Error fetching sections:", error));
  }, []);

  // Fetch articles for the active section
  useEffect(() => {
    if (activeSectionId) {
      console.log(`Fetching articles for section ID: ${activeSectionId}`);
      fetch(
        `https://thereallifestyle.zendesk.com/api/v2/help_center/sections/${activeSectionId}/articles.json`,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa(
                "yenray.ng@trlco.world/token:O4N6Fysz582712RvT2Aux2gvbdabCExJOoh966nK"
              ),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(
            `Fetched Articles for Section ${activeSectionId}:`,
            data.articles
          );
          setArticles(data.articles);
        })
        .catch((error) => console.error("Error fetching articles:", error));
    }
  }, [activeSectionId]);

  // Handle tab change
  const handleTabChange = (index) => {
    console.log(`Tab clicked: ${sections[index]?.id}`);
    if (sections[index]) {
      setActiveSectionId(sections[index].id);
    }
  };

  return (
    <div>
      <Topbar />
      <Header />
      <div className="w-full">
        <HeroSection
          breadCrumUrl={faqBreadcrumb}
          title={"Frequently Asked Questions"}
          description={` Discover answers to your questions about combining real estate and cryptocurrency on our innovative platform.`}
        />
      </div>

      <div className="md:pt-[60px] pt-10 md:pb-[120px] pb-[100px] tabs-pills px-4">
        <div className="max-w-5xl mx-auto">
          <div className="grid grid-cols-12">
            <div className="col-span-12 text-center">
              <h3 className="md:text-[32px] text-2xl leading-[40px] font-bold text-[#17271F] md:mb-6 mb-4">
                Explore topics
              </h3>
            </div>

            <div className="col-span-12 faq-main-section-ct">
              <Tabs
                aria-label="Pills"
                variant="pills"
                ref={tabsRef}
                onActiveTabChange={(tab) => handleTabChange(tab)}
              >
                {sections.map((section, index) => (
                  <Tabs.Item
                    key={section.id}
                    title={section.name}
                    active={activeSectionId === section.id}
                  >
                    <div className="faq-content-section md:p-[60px] py-5 px-4 shadow-[0px_4px_30px_0px_rgba(211,211,211,0.40)] bg-white rounded-3xl mt-10 [&_p]:text-base [&_p]:text-[#565656] [&_p]:font-medium [&_p]:leading-7">
                      <Accordion>
                        {articles.map((article) => (
                          <Accordion.Panel key={article.id} className="">
                            <Accordion.Title className="faq-button-section flex items-center md:text-xl text-base font-medium text-[#17271F] flex-wrap gap-3 min-h-[auto] h-auto md:py-10 py-6 px-2 border-t border-solid border-[#E0E0E0] first:border-t-0 hover:!bg-white [&.bg-gray-800]:!bg-white [&.bg-gray-100]:!text-[rgb(255,74,63)] [&.bg-gray-100]:!bg-white [&.bg-gray-800]:!text-[#ff4a3f] [&_svg]:hidden [&_h2]:w-full [&.bg-gray-100_.span-hide]:!hidden [&.bg-gray-800_.span-hide]:!hidden [&.bg-gray-100_.show-span]:!bg-[#ff4a3f] [&.bg-gray-800_.show-span]:!bg-[#ff4a3f]">
                              <div className="w-full flex items-center justify-between gap-3 ">
                                <span className="w-full block flex-1">
                                  {article.title}
                                </span>
                                <div className="flex items-center justify-center w-4">
                                  <span className="block w-4 h-[2px] bg-[#17271F] show-span"></span>
                                  <span className="block w-[2px] h-4 bg-[#17271F] -ml-[9px] span-hide"></span>
                                </div>
                              </div>
                            </Accordion.Title>
                            <Accordion.Content>
                              <p className="mb-2 text-gray-500 dark:text-gray-400 mt-4 block">
                                {article.body.replace(/<[^>]+>/g, "")}{" "}
                                {/* Stripping HTML tags from the content */}
                              </p>
                            </Accordion.Content>
                          </Accordion.Panel>
                        ))}
                      </Accordion>
                    </div>
                  </Tabs.Item>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
