import React, { useState, useEffect, useRef } from "react";
import pillar1 from "../assets/images/pillar-1.png";
import pillar2 from "../assets/images/pillar-2.png";
import pillar3 from "../assets/images/pillar-3.png";
import pillar4 from "../assets/images/pillar-4.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const LivingSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const carouselRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setTimeout(() => {
            if (carouselRef.current) {
              // carouselRef.current.to(3, 300);
              carouselRef.current.next(300);

            }
          }, 300);
        }
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div className="">
      <div className="md:py-[120px] py-[80px]">
        <div className="">
          <div className="mx-auto max-w-7xl px-5">
            <div className="text-center">
              <span className="text-[#17271F] text-[28px] md:leading-[55px] leading-[36px] mb-6 md:text-[48px] outfit-bold block">
                Experience the four pillars of TRL Living
              </span>
              <span className="text-[#565656] text-base outfit-regular md:mb-0">
                Our philosophy is built on four key pillars that ensure a
                superior living experience for our community.
              </span>
            </div>
          </div>
          <div className="w-full xl:pl-[150px] lg:pl-[80px] pl-[60px] hidden">
            <div className="md:mt-[100px] mt-[42px] overflow-auto experience-slide-scroll">
              <div className="grid grid-cols-4 items-start w-[2500px] gap-[200px] h-[520px]">
                <div className="w-full block ">
                  <div className="w-full relative ">
                    <img
                      alt=""
                      className="absolute z-[-1] md:left-[15px] left-[15px] w-[500px]"
                      src={pillar1}
                    />
                    <h5 className="xl:text-[60px] xl:leading-[70px] md:text-[48px] md:leading-[48px] text-[34px] leading-[42px] outfit-bold md:pt-16 pt-8 md:mb-10  mb-10 block">
                      Live <span className="block">seamless</span>
                    </h5>
                    <p className="text-[#17271F] xl:text-lg text-base leading-[24px] md:w-[88%] ml-auto md:mb-0 mb-5 md:pl-0 pl-5">
                      Our streamlined procedures make these processes
                      transparent, flexible, secure, and affordable. We believe
                      that simplicity is the key to enjoying life's greatest
                      pleasures.
                    </p>
                  </div>
                </div>
                <div className="w-full block">
                  <div className="w-full mx-auto relative ">
                    <img
                      alt=""
                      className="absolute z-[-1] md:left-[15px] left-[15px] w-[500px]"
                      src={pillar2}
                    />
                    <h5 className="xl:text-[60px] xl:leading-[70px] md:text-[48px] md:leading-[48px] text-[34px] leading-[42px] outfit-bold md:pt-16 pt-8 md:mb-10  mb-10 block">
                      Live <span className="block">accessible</span>
                    </h5>
                    <p className="text-[#17271F] xl:text-lg text-base leading-[24px] md:w-[88%] ml-auto md:mb-0 mb-5 md:pl-0 pl-5">
                      Our goal is to provide choices that empower you to shape
                      your living situation according to your desires, whether
                      you prefer traditional home ownership or flexible rental
                      arrangements.
                    </p>
                  </div>
                </div>
                <div className="w-full block">
                  <div className="w-full relative ">
                    <img
                      alt=""
                      className="absolute z-[-1] md:left-[15px] left-[15px] w-[500px]"
                      src={pillar3}
                    />
                    <h5 className="xl:text-[60px] xl:leading-[70px] md:text-[48px] md:leading-[48px] text-[34px] leading-[42px] outfit-bold md:pt-16 pt-8 md:mb-10  mb-10 block">
                      Live <span className="block">borderless</span>
                    </h5>
                    <p className="text-[#17271F] xl:text-lg text-base leading-[24px] md:w-[88%] ml-auto md:mb-0 mb-5 md:pl-0 pl-5">
                      Our services and solutions support a modern lifestyle,
                      allowing you to seamlessly transition between different
                      aspects of your life, no matter where you are.
                    </p>
                  </div>
                </div>
                <div className="w-full block">
                  <div className="w-full relative ">
                    <img
                      alt=""
                      className="absolute z-[-1] md:left-[15px] left-[15px] w-[500px]"
                      src={pillar4}
                    />
                    <h5 className="xl:text-[60px] xl:leading-[70px] md:text-[48px] md:leading-[48px] text-[34px] leading-[42px] outfit-bold md:pt-16 pt-8 md:mb-10  mb-10 block">
                      Live <span className="block">for real</span>
                    </h5>
                    <p className="text-[#17271F] xl:text-lg text-base leading-[24px] md:w-[88%] ml-auto md:mb-0 mb-5 md:pl-0 pl-5">
                      Our global passport and interconnected ecosystem are
                      designed to break down barriers and provide equal access
                      to opportunities.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* mobile view */}
          <div className="md:mt-[100px] mt-[42px] [&_.owl-dots]:!mt-[42px] block  px-5" ref={containerRef}>
            <OwlCarousel
              
              className="owl-theme"
              loop={false}
              margin={30}
              nav={false}
              dots={false}
              autoplayHoverPause
              smartSpeed={2000}
              responsive={{
                0: {
                  items: 1,
                  dots: true,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 3,
                },
              }}
              ref={carouselRef}
              // items={2.7}
              startPosition={currentSlide - 1}
              onChanged={(event) => setCurrentSlide(event.item.index + 1)}
            >
              <div className="item">
                <div className="md:max-w-[80%] max-w-full mx-auto relative lg:h-[520px] md:h-[380px] h-auto h-316px md:pl-0 px-5">
                  <img
                    alt=""
                    className="absolute z-[-1] md:left-[15px] left-[15px] md:w-auto w-full"
                    src={pillar1}
                  />
                  <h5 className="xl:text-[60px] xl:leading-[70px] md:text-[48px] md:leading-[48px] text-[34px] leading-[42px] outfit-bold md:pt-16 pt-8 md:mb-10  mb-10 block">
                    Live <span className="block">seamless</span>
                  </h5>
                  <p className="text-[#17271F] xl:text-lg text-base leading-[24px] md:w-[88%] ml-auto md:mb-0 mb-5 md:pl-0 pl-5">
                    Our streamlined procedures make these processes transparent,
                    flexible, secure, and affordable. We believe that simplicity
                    is the key to enjoying life's greatest pleasures.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="md:max-w-[80%] max-w-full mx-auto relative lg:h-[520px] md:h-[380px] h-auto md:pl-0 px-5">
                  <img
                    alt=""
                    className="absolute z-[-1] md:left-[15px] left-[15px] md:w-auto w-full"
                    src={pillar2}
                  />
                  <h5 className="xl:text-[60px] xl:leading-[70px] md:text-[48px] md:leading-[48px] text-[34px] leading-[42px] outfit-bold md:pt-16 pt-8 md:mb-10  mb-10 block">
                    Live <span className="block">accessible</span>
                  </h5>
                  <p className="text-[#17271F] xl:text-lg text-base leading-[24px] md:w-[88%] ml-auto md:mb-0 mb-5 md:pl-0 pl-5">
                    Our goal is to provide choices that empower you to shape
                    your living situation according to your desires, whether you
                    prefer traditional home ownership or flexible rental
                    arrangements.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="md:max-w-[80%] max-w-full mx-auto relative lg:h-[520px] md:h-[380px] h-auto md:pl-0 px-5">
                  <img
                    alt=""
                    className="absolute z-[-1] md:left-[15px] left-[15px] md:w-auto w-full"
                    src={pillar3}
                  />
                  <h5 className="xl:text-[60px] xl:leading-[70px] md:text-[48px] md:leading-[48px] text-[34px] leading-[42px] outfit-bold md:pt-16 pt-8 md:mb-10  mb-10 block">
                    Live <span className="block">borderless</span>
                  </h5>
                  <p className="text-[#17271F] xl:text-lg text-base leading-[24px] md:w-[88%] ml-auto md:mb-0 mb-5 md:pl-0 pl-5">
                    Our services and solutions support a modern lifestyle,
                    allowing you to seamlessly transition between different
                    aspects of your life, no matter where you are.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="md:max-w-[80%] max-w-full mx-auto relative lg:h-[520px] md:h-[380px] h-auto md:pl-0 px-5">
                  <img
                    alt=""
                    className="absolute z-[-1] md:left-[15px] left-[15px] md:w-auto w-full"
                    src={pillar4}
                  />
                  <h5 className="xl:text-[60px] xl:leading-[70px] md:text-[48px] md:leading-[48px] text-[34px] leading-[42px] outfit-bold md:pt-16 pt-8 md:mb-10  mb-10 block">
                    Live <span className="block">for real</span>
                  </h5>
                  <p className="text-[#17271F] xl:text-lg text-base leading-[24px] md:w-[88%] ml-auto md:mb-0 mb-5 md:pl-0 pl-5">
                    Our global passport and interconnected ecosystem are
                    designed to break down barriers and provide equal access to
                    opportunities.{" "}
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivingSlider;
