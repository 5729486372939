import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const TextAnimation = () => {
  const paragraphRef = useRef(null);

  useEffect(() => {
    const paragraph = paragraphRef.current;

    // Split text into spans for each character
    const text = paragraph.innerText;
    paragraph.innerHTML = text
      .split("")
      .map(
        (char) =>
          `<span style="display: inline-block; transition: color 0.5s;">${char}</span>`
      )
      .join("");

    const chars = paragraph.querySelectorAll("span");

    // Create a timeline for the animation
    const tl = gsap.timeline({
      transformOrigin: "right center",
      ease: "power5.in",
      scrollTrigger: {
        trigger: paragraph,
        start: "top 80%",
        end: "bottom 40%",
        scrub: true,
      },
    });

    // Animate each character
    tl.to(chars, {
      color: "#17271F",
      //   stagger: 0.5,
      stagger: {
        each: 0.05,
        from: "start",
        ease: "power1.inOut",
      },
    });
    gsap.set(".gsap-marker-start", { opacity: 0 });
    gsap.set(".gsap-marker-end", { opacity: 0 });
    // Cleanup function
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div>
      <p
        className="text-[#FFEEED] text-center md:text-5xl text-[28px] md:leading-[72px] leading-[40px] font-bold [&_span]:block"
        ref={paragraphRef}
      >
        We&nbsp;are&nbsp;the&nbsp;world's &nbsp;first &nbsp;360
        &nbsp;RWA&nbsp;ecosystem &nbsp;that &nbsp;drives &nbsp;the
        &nbsp;flywheel &nbsp;effect &nbsp;for &nbsp;both &nbsp;the
        &nbsp;consumer &nbsp;and &nbsp;investor &nbsp;when &nbsp;it &nbsp;comes
        &nbsp;to &nbsp;real &nbsp;estate &nbsp;living, &nbsp;tokenization,
        &nbsp;DeFi &nbsp;systems &nbsp;and &nbsp;utility &nbsp;rewards.
      </p>
    </div>
  );
};

export default TextAnimation;
